const YEAR_IN_MS = 365.25 * 24 * 60 * 60 * 1000; // 1000ms * 60secs * 60mins * 24hrs * 365.25 days accounts for leap years

export const dateDifInYears = (firstDate: Date, secondDate: Date) => (firstDate.getTime() - secondDate.getTime()) / YEAR_IN_MS;

export function calculateTenYearsAndDaysSince(closeOfEscrowDate: Date): boolean {
	// Calculate the difference in years
	const yearsDifference = dateDifInYears(new Date(), closeOfEscrowDate);

	// Check if the difference is greater than or equal to 10 years and 1 day
	return yearsDifference > (10 + (1 / 365.25));
}

export function getOneYearAndDayPostCloseDate(closeOfEscrowDate: Date): string | null {

	// Check if the difference is greater than or equal to 1 year and 1 day
	if (dateDifInYears(new Date(), closeOfEscrowDate) > (1 + (1 / 365.25))) {
		return null;
	}

	// Check if the current year is a leap year
	const coeYear = closeOfEscrowDate.getFullYear();
	const isCoeYearLeap = (coeYear % 4 === 0 && coeYear % 100 !== 0) || coeYear % 400 === 0;

	// Calculate the date one year and one day post-close
	const yearAndDay = isCoeYearLeap ? 367 : 366;
	closeOfEscrowDate.setDate(closeOfEscrowDate.getDate() + yearAndDay);

	const month = String(closeOfEscrowDate.getMonth() + 1).padStart(2, '0');
	const day = String(closeOfEscrowDate.getDate()).padStart(2, '0');
	const year = String(closeOfEscrowDate.getFullYear()).slice(-2);

	const yearAndDayPostCloseDateStr = `${month}/${day}/${year}`;

	return yearAndDayPostCloseDateStr;
}
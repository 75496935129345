import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ConstructionUpdateAttachments } from 'src/app/modules/core/models/shr.model';

import { SUPPORTED_VIDEO_EXTENSIONS } from 'src/app/modules/shared/constants/file-extensions';
import { Breakpoints } from 'src/app/modules/shared/types/breakpoints';
import { keyPressHandler } from 'src/app/modules/shared/utilities/accessibility.utils';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

interface LightboxData {
	media: ConstructionUpdateAttachments[];
	selectedMediaIndex: number;
}

@Component({
	selector: 'cp-lightbox',
	templateUrl: './lightbox.component.html',
	styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent extends UnsubscribeOnDestroy implements OnInit {
	@ViewChild('thumbnailContainer') thumbnailContainer?: ElementRef;

	keyPressHandler = keyPressHandler;

	isDesktop?: boolean;
	media: ConstructionUpdateAttachments[] = [];
	selectedMedia?: ConstructionUpdateAttachments;
	selectedMediaIndex: number = 0;
	showFullscreenMedia: boolean = false;
	touchStart: number = 0;

	get mediaCountText(): string {
		return this.media.length > 0 ? `${this.selectedMediaIndex + 1} of ${this.media.length}` : '';
	}

	constructor(
		public dialogRef: MatDialogRef<LightboxComponent>,
		@Inject(MAT_DIALOG_DATA) public data: LightboxData,
		private breakpointObserver: BreakpointObserver,
	) {
		super();

		this.media = data.media;
		this.selectedMediaIndex = data.selectedMediaIndex;
		this.selectedMedia = this.media.at(this.selectedMediaIndex);
	}

	ngOnInit(): void {
		this.breakpointObserver.observe(Breakpoints.desktop)
			.pipe(this.takeUntilDestroyed())
			.subscribe((result: BreakpointState) => {
				this.isDesktop = result.matches;
			});
	}

	// Sets the currently selected media
	changeSelectedMedia(index: number): void {
		if (index < 0) {
			this.selectedMediaIndex = this.media.length - 1;
		} else if (index >= this.media.length) {
			this.selectedMediaIndex = 0;
		} else {
			this.selectedMediaIndex = index;
		}

		this.selectedMedia = this.media.at(this.selectedMediaIndex);
		
		// Scroll thumbnail into view
		document.getElementById(`thumbnail-${this.selectedMediaIndex}`)?.scrollIntoView({
			behavior: 'smooth',
		});
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	// Logs the x position of touch start event
	handleTouchStart(event: TouchEvent) {
		this.touchStart = event.touches[0].screenX;
	}

	// Moves to next media based on swipe direction
	handleTouchEnd(event: TouchEvent) {
		const touchEnd = event.changedTouches[0].screenX;

		// Swipe left, move to the right image, swipe right move to the left image
		if(this.touchStart > touchEnd) {
			this.changeSelectedMedia(this.selectedMediaIndex + 1);
		} else if (this.touchStart < touchEnd) {
			this.changeSelectedMedia(this.selectedMediaIndex - 1);
		}
	}

	// Determines if the provided index is the active media
	isActiveMedia(index: number): boolean {
		return this.selectedMediaIndex === index;
	}

	// Used to scroll thumbnails in the html with left and right arrows
	scrollThumbnails(direction: 'left' | 'right'): void {
		const scrollDistance = 500;

		if (this.thumbnailContainer) {
			const currentLeftPos = this.thumbnailContainer?.nativeElement.scrollLeft;

			this.thumbnailContainer.nativeElement.scrollTo({
				left: direction === 'left' ? currentLeftPos - scrollDistance : currentLeftPos + scrollDistance,
				behavior: 'smooth',
			});
		}
	}

	// Displays or collapses the fullscreen media in tablet and mobile experiences
	toggleFullscreenMedia(show: boolean) {
		// Only show fullscreen media if on tablet or mobile and not a video
		const isVideo = this.selectedMedia && this.selectedMedia.fileExtension ? SUPPORTED_VIDEO_EXTENSIONS.has(this.selectedMedia?.fileExtension) : false;
		if (!this.isDesktop && !isVideo) {
			this.showFullscreenMedia = show;
		}
	}
}
import { Component, ElementRef, Inject, Input, OnChanges, SimpleChanges, ViewChild, forwardRef } from '@angular/core';

import { Cloudinary, CloudinaryImage, CloudinaryVideo } from '@cloudinary/url-gen';

import { SUPPORTED_IMAGE_EXTENSIONS, SUPPORTED_VIDEO_EXTENSIONS } from 'src/app/modules/shared/constants/file-extensions';
import { isTouchDevice, keyPressHandler } from 'src/app/modules/shared/utilities/accessibility.utils';

@Component({
	selector: 'cp-cloudinary-media',
	templateUrl: './cloudinary-media.component.html',
	styleUrls: ['./cloudinary-media.component.scss']
})
export class CloudinaryMediaComponent implements OnChanges {
	@Input({ required: true }) publicId!: string;
	@Input({ required: true }) fileExtension!: string;
	@Input() disablePlayback: boolean = false;
	@Input() isClickable: boolean = false;
	@Input() showControls: boolean = false;
	@Input() isOverflow: boolean = false; // Whether there are additional media beyond the count of the grid
	@Input() altText: string = '';

	@ViewChild('videoElement') videoElement?: ElementRef;
	
	keyPressHandler = keyPressHandler;
	isTouchDevice: boolean = isTouchDevice();
	isOverlayHovered: boolean = false;
	img?: CloudinaryImage;
	vid?: CloudinaryVideo;

	constructor(
		@Inject(forwardRef(() => Cloudinary)) private cloudinary: Cloudinary
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		this.fileExtension = this.fileExtension.toLowerCase();

		if (changes['contentType'] || changes['publicId']) {
			if (SUPPORTED_IMAGE_EXTENSIONS.has(this.fileExtension)) {
				this.img = this.cloudinary.image(this.publicId);
				this.vid = undefined;
			} else if (SUPPORTED_VIDEO_EXTENSIONS.has(this.fileExtension)) {
				this.img = undefined;
				this.vid = this.cloudinary.video(this.publicId);
			}
		}
	}

	mouseOverHandler(enteringIconOverlay: boolean): void {
		this.isOverlayHovered = enteringIconOverlay;
	}

	// overlay click method is to hide the overlay and start playing the video when the lightbox is open.
	overlayClick() {
		if (this.videoElement && !this.disablePlayback) {
			if (this.videoElement.nativeElement.paused) {
				this.videoElement.nativeElement.play();
			} else {
				this.videoElement.nativeElement.pause();
			}
		}
	}
}
// The image formats taken form the cloudinary website as supported upload formats: https://cloudinary.com/documentation/image_transformations#supported_image_formats
export const SUPPORTED_IMAGE_EXTENSIONS = new Map<string, Array<Array<number>>>([
	['ai', [
		[0x25, 0x50, 0x44, 0x46]
	]],
	['avif', []],
	['gif', [
		[0x47, 0x49, 0x46, 0x38, 0x39, 0x61]
	]],
	['png', [
		[0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A]
	]],
	['webp', [
		[0x52, 0x49, 0x46, 0x46]
	]],
	['bmp', [
		[0x42, 0x4D]
	]],
	['bw', []],
	['djvu', []],
	['dng', []],
	['ps', [
		[0x38, 0x42, 0x50, 0x53]
	]],
	['ept', []],
	['eps', [
		[0x25, 0x21, 0x50, 0x53, 0x2D, 0x41, 0x64, 0x6F, 0x62, 0x65, 0x2D, 0x33, 0x2E, 0x30, 0x20, 0x45, 0x50, 0x53, 0x46, 0x2D, 0x33, 0x20, 0x30],
		[0xC5, 0xD0, 0xD3, 0xC6]
	]],
	['eps3', []],
	['fbx', []],
	['flif', []],
	['glb', []],
	['gltf', []],
	['heif', []],
	['heic', []],
	['ico', [
		[0x00, 0x00, 0x01, 0x00]
	]],
	['indd', []],
	['jpg', [
		[0xFF, 0xD8]
	]],
	['jpe', [
		[0xFF, 0xD8]
	]],
	['jpeg', [
		[0xFF, 0xD8]
	]],
	['jp2', [
		[0x00, 0x00, 0x00, 0x0C, 0x6A, 0x50, 0x20, 0x20, 0x0D, 0x0A]
	]],
	['wdp', []],
	['jxl', []],
	['obj', []],
	['pdf', [
		[0x25, 0x50, 0x44, 0x46]
	]],
	['ply', []],
	['psd', [
		[0x38, 0x42, 0x50, 0x53]
	]],
	['arw', []],
	['cr2', []],
	['svg', []],
	['tga', []],
	['tif', [
		[0x49, 0x20, 0x49],
		[0x49, 0x49, 0x2A, 0x00],
		[0x4D, 0x4D, 0x00, 0x2A],
		[0x4D, 0x4D, 0x00, 0x2B]
	]],
	['tiff', [
		[0x49, 0x20, 0x49],
		[0x49, 0x49, 0x2A, 0x00],
		[0x4D, 0x4D, 0x00, 0x2A],
		[0x4D, 0x4D, 0x00, 0x2B]
	]],
	['u3ma', []],
	['usdz', []]
]);

// These formats taken from the cloudinary website as supported upload formats: https://cloudinary.com/documentation/video_manipulation_and_delivery#supported_video_formats
export const SUPPORTED_VIDEO_EXTENSIONS = new Map<string, Array<Array<number>>>([
	['3g2', [
		[0x00, 0x00, 0x00, 0x14, 0x66, 0x74, 0x79, 0x70, 0x33, 0x67, 0x70],
		[0x00, 0x00, 0x00, 0x20, 0x66, 0x74, 0x79, 0x70, 0x33, 0x67, 0x70]
	]],
	['3gp', [
		[0x00, 0x00, 0x00, 0x14, 0x66, 0x74, 0x79, 0x70, 0x33, 0x67, 0x70],
		[0x00, 0x00, 0x00, 0x20, 0x66, 0x74, 0x79, 0x70, 0x33, 0x67, 0x70]
	]],
	['avi', [
		[52494646]
	]],
	['fiv', []],
	['m3u8', []],
	['ts', [
		[0x47]
	]],
	['m2ts', []],
	['mts', []],
	['mov', [
		[0x00],
		[0x00, 0x00, 0x00, 0x14, 0x66, 0x74, 0x79, 0x70, 0x71, 0x74, 0x20, 0x20],
	]],
	['mkv', [
		[0x1A, 0x45, 0xDF, 0xA3]
	]],
	['mp4', [
		[0x00, 0x00, 0x00, 0x14, 0x66, 0x74, 0x79, 0x70, 0x69, 0x73, 0x6F, 0x6D],
		[0x00, 0x00, 0x00, 0x18, 0x66, 0x74, 0x79, 0x70, 0x33, 0x67, 0x70, 0x35],
		[0x00, 0x00, 0x00, 0x1C, 0x66, 0x74, 0x79, 0x70, 0x4D, 0x53, 0x4E, 0x56, 0x01, 0x29, 0x00, 0x46, 0x4D, 0x53, 0x4E, 0x56, 0x6D, 0x70, 0x34, 0x32]
	]],
	['mpeg', [
		[0x00, 0x00, 0x01, 0x00],
		[0xFF, 0xD8]
	]],
	['mpd', []],
	['mxf', [
		[0x06, 0x0E, 0x2B, 0x34, 0x02, 0x05, 0x01, 0x01, 0x0D, 0x01, 0x02, 0x01, 0x01, 0x02],
		[0x3C, 0x43, 0x54, 0x72, 0x61, 0x6E, 0x73, 0x54, 0x69, 0x6D, 0x65, 0x6C, 0x69, 0x6E, 0x65, 0x3E]
	]],
	['ogv', [
		[0x4F, 0x67, 0x67, 0x53, 0x00, 0x02, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00]
	]],
	['webm', [
		[0x1A, 0x45, 0xDF, 0xA3]
	]],
	['wmv', [
		[0x30, 0x26, 0xB2, 0x75, 0x8E, 0x66, 0xCF, 0x11, 0xA6, 0xD9, 0x00, 0xAA, 0x00, 0x62, 0xCE, 0x6C]
	]]
]);